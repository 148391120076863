import React from 'react';

const IconLoader = () => (
  <svg
    id="Layer_1"
    enable-background="new 0 0 512.028 512.028"
    height="512"
    viewBox="0 0 512.028 512.028"
    width="512"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <path
          d="m463.014 256.013c0 207.99-131.4 252.56-252.14 248.22-64.01-2.3-125.02-18.36-161.86-31.1 19.06-224.34 0-434.24 0-434.24 36.87-12.75 97.94-28.82 162-31.1 120.7-4.309 252 40.3 252 248.22z"
          fill="#eff4f4"
        />
        <path
          d="m463.014 256.013c0 207.99-131.4 252.56-252.14 248.22 111.51-4.12 222.13-57.38 222.13-248.22 0-190.76-110.53-244.06-221.99-248.22 120.7-4.309 252 40.3 252 248.22z"
          fill="#daeaef"
        />
        <path
          d="m411.864 138.103c-11.94-26.91-29.26-48.64-51.5-64.57-36.3-26.01-86.4-37.98-149.01-35.59-2.34.08-4.69.19-7.07.32-49.6 2.68-94.88 13.64-123.48 22.09 4.2 59.16 12.83 217.86.02 391.32 28.6 8.45 73.87 19.4 123.46 22.09 2.4.13 4.78.24 7.14.32 62.56 2.37 112.65-9.6 148.94-35.6 22.24-15.93 39.56-37.65 51.5-64.57 14.03-31.62 21.14-71.29 21.14-117.9 0-46.62-7.11-86.289-21.14-117.91zm-212.99 244.17c9.05-133.42 0-256.07 0-256.07s120.4-40.86 120.4 128.04c0 168.89-120.4 128.03-120.4 128.03z"
          fill="#56788b"
        />
        <path
          d="m433.004 256.013c0 46.61-7.11 86.28-21.14 117.9-11.94 26.92-29.26 48.64-51.5 64.57-36.29 26-86.38 37.97-148.94 35.6 48.75-1.97 88.65-13.9 118.94-35.6 22.24-15.93 39.56-37.65 51.5-64.57 14.03-31.62 21.14-71.29 21.14-117.9 0-46.62-7.11-86.29-21.14-117.91-11.94-26.91-29.26-48.64-51.5-64.57-30.31-21.71-70.22-33.64-119.01-35.59 62.61-2.39 112.71 9.58 149.01 35.59 22.24 15.93 39.56 37.66 51.5 64.57 14.03 31.621 21.14 71.29 21.14 117.91z"
          fill="#415e6f"
        />
      </g>
      <g>
        <path d="m381.74 42.602c-44.77-31.889-105.235-45.995-179.734-41.915-70.196 3.84-132.09 23.039-155.443 31.115-3.259 1.127-5.33 4.332-5.019 7.766.019.198 1.817 20.168 3.729 53.681.237 4.135 3.771 7.291 7.916 7.061 4.135-.236 7.296-3.78 7.061-7.915-1.323-23.182-2.596-39.963-3.27-48.28 45.115-14.91 212.906-62.768 316.057 10.704 54.728 38.981 82.477 106.672 82.477 201.192 0 21.533-1.457 41.943-4.329 60.661-.629 4.094 2.182 7.922 6.275 8.551.386.059.769.088 1.146.088 3.641 0 6.835-2.655 7.404-6.363 2.988-19.469 4.503-40.643 4.503-62.936.001-99.651-29.867-171.453-88.773-213.41z" />
        <path d="m452.728 347.314c-3.979-1.132-8.134 1.171-9.27 5.155-15.566 54.568-46.521 94.242-92.007 117.921-42.264 22.002-96.637 30.438-161.61 25.074-58.192-4.804-109.118-19.68-132.864-27.556 10.743-130.802 8.743-256.101 5.127-338.684-.182-4.139-3.719-7.354-7.821-7.165-4.139.181-7.346 3.683-7.165 7.821 3.652 83.376 5.648 210.497-5.576 342.616-.291 3.42 1.777 6.601 5.021 7.723 21.09 7.294 77.149 24.836 142.045 30.194 13.042 1.077 25.687 1.614 37.925 1.614 51.419 0 95.646-9.488 131.845-28.333 49.286-25.657 82.764-68.424 99.505-127.112 1.136-3.981-1.171-8.132-5.155-9.268z" />
        <path d="m85.658 364.273c-4.181-.172-7.629 3.053-7.797 7.19-1.094 27.062-2.614 53.861-4.519 79.654-.26 3.52 1.969 6.744 5.353 7.745 29.486 8.718 75.139 19.679 125.18 22.389 7.767.421 15.348.632 22.756.632 57.115-.001 103.502-12.514 138.104-37.301 23.338-16.719 41.502-39.471 53.987-67.623 14.454-32.586 21.782-73.279 21.782-120.949s-7.328-88.362-21.781-120.947c-12.486-28.152-30.65-50.904-53.988-67.624-39.082-27.997-93.195-40.333-160.858-36.67-50.053 2.712-95.711 13.676-125.199 22.396-3.376.999-5.604 4.211-5.354 7.724 3.574 50.304 9.067 153.076 5.702 276.193-.113 4.141 3.151 7.589 7.292 7.702 4.181.103 7.589-3.152 7.702-7.292 3.253-119.015-1.729-219.135-5.313-271.618 28.718-8.167 70.527-17.665 115.98-20.127 64.167-3.478 115.072 7.924 151.312 33.886 21.136 15.141 37.625 35.836 49.013 61.511 13.599 30.658 20.493 69.305 20.493 114.866s-6.895 84.208-20.494 114.867c-11.387 25.674-27.877 46.37-49.012 61.51-36.245 25.966-87.154 37.366-151.312 33.886-45.435-2.461-87.23-11.953-115.945-20.117 1.712-24.083 3.093-48.974 4.108-74.087.166-4.138-3.053-7.629-7.192-7.796z" />
        <path d="m196.47 389.377c1.026.348 14.48 4.787 32.493 4.786 15.757 0 35.004-3.398 52.455-15.905 30.097-21.568 45.356-63.293 45.356-124.017 0-24.283-2.48-45.785-7.373-63.91-1.08-4-5.198-6.367-9.196-5.286-3.999 1.08-6.365 5.196-5.286 9.195 4.549 16.85 6.855 37.038 6.855 60 0 55.532-13.117 93.129-38.987 111.747-24.234 17.442-53.951 13.333-66.019 10.625 7.22-113.229 1.626-218.598-.001-244.769 6.207-1.414 17.099-3.203 29.597-2.184 25.459 2.074 44.829 14.529 57.573 37.02 2.044 3.604 6.619 4.87 10.223 2.828s4.87-6.619 2.828-10.223c-19.11-33.726-48.291-42.856-69.406-44.576-22.452-1.828-40.361 4.14-41.112 4.396-3.236 1.098-5.32 4.245-5.069 7.653.09 1.222 8.896 123.686-.003 255.009-.231 3.395 1.851 6.518 5.072 7.611z" />
      </g>
    </g>
  </svg>
);

export default IconLoader;
